import Navbar from "./component/pages/Navbar";
import "./component/scss/Main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/pages/Home";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./App.scss";
import Form1 from "./component/pages/Form1";
import Page404 from "./component/pages/Page404";
import { DissconnectWallet, GetChainId, MetaMasklogin, WalletConnect, getUserAddress } from "./Web3/web3";

import { useEffect } from "react";

function App() {

  const api = "https://maal-bridge-api.onrender.com"
  const setUser = useStoreActions((action)=> action.setUser)
  const user = useStoreState((state) => state.user);
  const API = ""

 
  useEffect(() => {
    const init = async () => {

      const wallet = window.localStorage.getItem("wallet");
      if (wallet) {
        await Metamask();
      }
    };

    init();
  }, []);

  const WalletC = async () => {
    await WalletConnect();
    const add = await getUserAddress();
    setUser(add);

  };

  const Metamask = async () => {
    await MetaMasklogin();
    const add = await getUserAddress();
    window.localStorage.setItem("wallet", "wallet");
    setUser(add);
  };



  const Dissconnect = async () => {
    await DissconnectWallet();
    setUser(undefined);
    window.localStorage.removeItem("wallet");
  };

  try {
    window.ethereum.on("accountsChanged", function (accounts) {
      setUser(accounts[0]);
    });
  } catch (error) { }

  try {
    window.ethereum.on("chainChanged", function (accounts) {
      window.location.reload();
    });
  } catch (error) { }


  return (
    <Router>
      <Navbar user={user} Metamask={Metamask} WalletC={WalletC} Dissconnect={Dissconnect} api={api}/>
      <Routes>
        <Route path="/" element={<Home user={user} API={API} Metamask={Metamask} WalletC={WalletC} Dissconnect={Dissconnect} api={api}/>} />
        <Route path="/form" element={<Form1 user={user} API={API} api={api}/>} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
