import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import usdt from "../Image/tether.png";
import maal_icon from "../Image/maal_icon_white.png";
import check from "../Image/check.png";

import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineArrowDown, AiOutlineDown } from "react-icons/ai";
import maal_icon_black from "../Image/maal_icon.png";
import logo from "../Image/maal_icon_white.png";
import logo_black from "../Image/maal_icon.png";
import meta from "../Image/meta.png";
import mantle from "../Image/mantle.jpg";
import walletconnect from "../Svg/walletconnect.svg";
import { Modal, ProgressBar, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  GetChainId,
  SwitchNetwork,
  get_Token_Balance,
  towie,
  usdtTransfer,
  userBalanceOfChain,
  xausBalanceMaalChain,
} from "../../Web3/web3";
import {
  NetworkFromChainId,
  bridgeContract,
  idToExplore,
  targetAddress,
  tokenOfNetworks,
} from "../../Web3/Contract";
import axios from "axios";
import { toast } from "react-toastify";

const success = (msg) => toast.success(msg);
const error = (msg) => toast.error(msg);

function Home({ user, Metamask, WalletC, Dissconnect, api }) {
  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "light-theme") {
      setTheme("light-theme");
    } else {
      setTheme("dark-theme");
    }
  }, []);
  const [theme, setTheme] = useState("dark-theme");
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  //xaus_amount = token_amount / ((xau_price * 0.425) / 31.1034768)
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [chainID, setChainID] = useState(1);
  const [stableCoinBalance, setStableCoinBalance] = useState();
  const [xausBalance, setXausBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [receiveXaus, setReceiveXaus] = useState(0);
  const [open, setOpen] = useState(false);
  const [bridgeBalance, setBridgeBalance] = useState(0);
  const [usertx, setUserTx] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const init = async () => {
      const id = await GetChainId();
      setChainID(id);
      const balance = await get_Token_Balance(
        tokenOfNetworks[`${NetworkFromChainId[`${id}`]}`],
        user
      );
      setStableCoinBalance(balance);

      const xauBalance = await xausBalanceMaalChain(user);
      console.log("xaus balance", xauBalance);
      setXausBalance(xauBalance);

      const brigeBalance = await xausBalanceMaalChain(bridgeContract);
      setBridgeBalance(brigeBalance);
      goldprice();
      getTransaction();
    };
    init();
  }, [user, open]);

  const getTransaction = () => {
    try {
      axios
        .post(`${api}/getTxDetails/${user}`)
        .then((res) => {
          setUserTx(res.data);
          console.log("Trn", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const goldprice = async () => {
    const xau = await axios
      .get("https://www.goldapi.io/api/XAU/USD", {
        headers: {
          "x-access-token": "goldapi-3etxdtl90ttr5o-io",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setReceiveXaus((res.data.price * 0.425) / 31.1034768);
      })
      .catch(console.error);
    return xau;
  };

  const transferUSDT = async () => {
    try {
      if (amount > bridgeBalance) {
        error("Bridge has insufficient balance");
        return;
      }
      setOpen(true);
      const a = await towie(amount);
      const data = await usdtTransfer(
        tokenOfNetworks[`${NetworkFromChainId[`${chainID}`]}`],
        user,
        a
      );
      if (data.status) {
        setProgress(50);
        apiCall(a, data);
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const apiCall = async (a, data) => {
    try {
      let postdata = JSON.stringify({
        from: user,
        to: targetAddress,
        network: NetworkFromChainId[`${chainID}`],
        tokenAmount: a,
        txHash: data.transactionHash,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${api}/transfer`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer $A89847uErD%**!ZAPAK",
        },
        data: postdata,
      };
      axios
        .request(config)
        .then((response) => {
          console.log(response);
          setProgress(100);
          // setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setOpen(false);
        });
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const sliceTx = (tx) => {
    if (typeof tx != "string") {
      return;
    }
    const first = tx.slice(0, 10);
    const second = tx.slice(55);
    return first + "..." + second;
  };

  const sliceAddress = (tx) => {
    if (typeof tx != "string") {
      return;
    }
    const first = tx.slice(0, 5);
    const second = tx.slice(36);
    return first + "..." + second;
  };

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        success("copied: " + text);
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard: " + err);
      });
  }

  const Retry = async (to, networkName, amount, hash) => {
    try {
      setOpen(true);
      setProgress(50);
      try {
        let postdata = JSON.stringify({
          from: user,
          to: to,
          network: networkName,
          tokenAmount: amount,
          txHash: hash,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${api}/transfer`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer $A89847uErD%**!ZAPAK",
          },
          data: postdata,
        };
        axios
          .request(config)
          .then((response) => {
            console.log(response);
            setProgress(100);
            // setOpen(false);
          })
          .catch((error) => {
            console.log(error);
            setOpen(false);
          });
      } catch (error) {
        console.log(error);
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  // const getRowColor = (index) => {
  //   return index % 2 === 0 ? "" : "green"; // Alternating between red and green
  // };

  return (
    <>
      <div className="t-a-c">
        <div className="d-f j-c-s-e flex-wrap">
          <div className="">
            <div className="box d-i-b p-x-2 p-y-2 b-r-10 m-t-3 min-w-30 ">
              {/* <div className="t-a-r m-b-1 c-p" onClick={handleShow2}>
            Connect
          </div> */}{" "}
              <div className="box_shadow b-r-10 m-b-2">
                <div className="pa-1 d-f j-c-s-b a-i-c box_clr b-r-t-r">
                  <div className="f-w-600 d-f a-i-c ">
                    <img src={usdt} className="w-2 m-r-1" />
                    USDT
                  </div>
                  <div
                    className="t-a-l d-f a-i-c c-p w-s-n-w"
                    onClick={handleShow3}
                  >
                    <div className="f-s-0_8 m-r-0_5">Network</div>
                    <div className="f-w-600">
                      {NetworkFromChainId[`${chainID}`]}{" "}
                      <AiOutlineDown className="b-c-t" />
                    </div>
                  </div>
                </div>
                <div className="pa-1 d-f a-i-c j-c-s-b">
                  <div className="">
                    <input
                      type="text"
                      className="w-100 b-c-t b-n "
                      placeholder="0 USD"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="">
                    {" "}
                    <button
                      className="pa-0_5 box_clr b-r-10 b-n f-s-0_8 "
                      onClick={() => setAmount(stableCoinBalance)}
                    >
                      Max
                    </button>
                  </div>
                  <div className="t-a-r f-s-0_8">
                    Balance
                    <br />
                    {stableCoinBalance}
                  </div>
                </div>
              </div>
              <div className="t-a-c">
                <AiOutlineArrowDown className="f-s-2 m-b-2" />
              </div>
              <div className="box_shadow b-r-10 m-b-3">
                <div className="pa-1 d-f j-c-s-b a-i-c box_clr b-r-t-r">
                  <div className="f-w-600 d-f a-i-c ">
                    <img
                      src="https://bafkreibvjjfjeycn5cphvc5g4zzvkx2gttg3alhxns6qex4hz44eqrh4tq.ipfs.nftstorage.link/"
                      className="w-1_5 m-r-1"
                    />
                    XAUS
                  </div>
                  <div className="f-w-600 d-f a-i-c ">
                    {theme == "dark-theme" ? (
                      <img
                        src={logo}
                        alt=""
                        className="logo animate__animated animate__rubberBand w-1_5 m-r-1"
                      />
                    ) : (
                      <img
                        src={logo_black}
                        alt=""
                        className="logo animate__animated animate__rubberBand w-1_5 m-r-1"
                      />
                    )}
                    MAAL CHAIN
                  </div>
                </div>
                <div className="pa-1 d-f  j-c-s-b">
                  <div className="">
                    <input
                      type="text"
                      className="w-100 b-c-t b-n"
                      value={amount / receiveXaus}
                      disabled
                    />
                  </div>
                  <div className="t-a-r f-s-0_8">
                    Balance
                    <br />
                    {xausBalance}
                  </div>
                </div>
              </div>
              <div className="d-f a-i-c j-c-s-b m-y-0_5 ">
                <div className="">Gas on destination</div>
                <div className="">15 GWEI</div>
              </div>
              {/* <div className="d-f a-i-c j-c-s-b m-y-0_5 ">
            <div className="">You will receive</div>
            <div className="">--</div>
          </div> */}
              <div className="d-f a-i-c j-c-s-b m-t-0_5 m-b-3 ">
                <div className="">Bridge Balance</div>
                <div className="">
                  {bridgeBalance ? bridgeBalance.toFixed(2) : ""} XAUS
                </div>
              </div>
              <div className="">
                <button
                  className="pa-1_5 b-r-10 b-n gray_bg_clr w-100 f-w-600 f-s-1_25 c-w"
                  onClick={() => transferUSDT()}
                >
                  submit
                </button>
              </div>
            </div>
          </div>
          <div className="bg_image m-t-3 b-r-10">
            <div className="max-w-30 c-w  p-x-2 p-y-3  t-a-l">
              <div className="m-b-2 ">
                <span className="f-w-600">MaalBridge:</span> An Exclusive
                Gateway from Ethereum, Binance Smart Chain, and Polygon to
                MaalChain. Presently, our bridge supports the transition of USDT
                (both ERC20 and BEP20 forms) to XAUS (in the MAAL20 format).
              </div>
              <div className="m-b-2">
                <span className="f-w-600"> Note:</span> The capability to bridge
                from XAUS (MAAL20) back to USDT (ERC20/BEP20) is under
                development and will be introduced in upcoming releases.
              </div>

              <div className="m-b-2 ">
                <span className="f-w-600"> XAUS Token Value Insight:</span> Each
                XAUS token represents 0.425 grams of 22 Karat Gold.
                Consequently, the current value of a single XAUS token mirrors
                the prevailing USD price for 0.425 grams of 22 Karat Gold. To
                stay updated with the latest gold price per Troy Ounce,{" "}
                <a
                  href="https://www.kitco.com/charts/livegold.html"
                  target="_blank"
                >
                  {" "}
                  please refer to Kitco's Live Gold Chart.
                </a>
              </div>
              <div className="">
                {" "}
                <span className="f-w-600">Current XAUS Price:</span>{" "}
                <span className="f-w-600 f-s-1_25 ">
                  {" "}
                  {receiveXaus ? receiveXaus.toFixed(2) : ""} USDT
                </span>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="container-kws m-y-4 t-a-c">
          <div className="m-b-2 m-t-5 f-w-600 f-s-1_5 t-a-l">
            Your Transaction History
          </div>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              background: "transparent",
            }}
            className="d-b m-a"
          >
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="f-w-600 bg_d_b_clr">
                    <TableCell
                      align="center"
                      style={{ minWidth: "170" }}
                      className="bg_d_b_clr"
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "170" }}
                      className="bg_d_b_clr"
                    >
                      FROM
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "170" }}
                      className="bg_d_b_clr"
                    >
                      TO
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "170" }}
                      className="bg_d_b_clr"
                    >
                      NETWORK
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "170" }}
                      className="bg_d_b_clr"
                    >
                      STATUS
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "170" }}
                      className="bg_d_b_clr"
                    >
                      SENT HASH
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "170" }}
                      className="bg_d_b_clr"
                    >
                      RECEIVE HASH
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usertx &&
                    usertx.map((res, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="center">
                            {usertx.indexOf(res) + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => copyToClipboard(res.from)}
                          >
                            {sliceAddress(res.from)}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => copyToClipboard(res.to)}
                          >
                            {sliceAddress(res.to)}
                          </TableCell>
                          <TableCell align="center">{res.network}</TableCell>
                          <TableCell align="center">
                            {res.processed ? (
                              "Success"
                            ) : (
                              <button
                                onClick={() =>
                                  Retry(
                                    res.to,
                                    res.network,
                                    res.sentUSDT,
                                    res.xausTransferTxHash
                                  )
                                }
                                className="or_clr c-b p-x-2 p-y-0_5 b-r-10 b-n"
                              >
                                Retry
                              </button>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={`${idToExplore[`${chainID}`]}/tx/${
                                res.txHash
                              }`}
                              target="_black"
                            >
                              {" "}
                              {sliceTx(res.txHash)}
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={`https://maalscan.io/tx/${res.xausTransferTxHash}`}
                              target="_black"
                            >
                              {sliceTx(res.xausTransferTxHash)}
                            </a>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {usertx.length == 0 ? (
                <div className="p-y-5 t-a-c clr f-w-600">
                  No data to show.....
                </div>
              ) : (
                ""
              )}
            </TableContainer>
          </Paper>
        </div>
      </div>
      <Modal show={show2} onHide={handleClose2} className="">
        <div className="">
          <Modal.Header>
            <Modal.Title className=" c-i">
              Please Connect Your Wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
              <img src={meta} alt="" className="w-7" />
              <img src={walletconnect} alt="" className="w-5" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal show={show3} onHide={handleClose3} className="">
        <div className="">
          <Modal.Header>
            <Modal.Title className=" c-i">Network</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="walletconnect   ">
              <div
                className="network pa-1 d-f a-i-c c-p "
                onClick={() => SwitchNetwork("Ethereum")}
              >
                {" "}
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                  alt=""
                  className="w-2 m-r-1"
                />{" "}
                Ethereum Chain
              </div>
              <div
                className="network pa-1 d-f a-i-c c-p "
                onClick={() => SwitchNetwork("Polygon")}
              >
                {" "}
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
                  alt=""
                  className="w-2 m-r-1"
                />{" "}
                Polygon Chain
              </div>
              <div
                className="network pa-1 d-f a-i-c c-p "
                onClick={() => SwitchNetwork("Binance Smart Chain")}
              >
                {" "}
                <img
                  src="https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850"
                  alt=""
                  className="w-2 m-r-1"
                />{" "}
                Binance Smart Chain
              </div>

              {/* <div
                className="network pa-1 d-f a-i-c c-p "
                onClick={() => SwitchNetwork("Mantle")}
              >
                {" "}
                <img src={mantle} alt="" className="b-r-50 w-2 m-r-1" /> Mantle
              </div>

              <div
                className="network pa-1 d-f a-i-c c-p "
                onClick={() => SwitchNetwork("Sepolia")}
              >
                {" "}
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                  alt=""
                  className="w-2 m-r-1"
                />{" "}
                Sepolia
              </div> */}
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Modal show={open} className="">
        <div className="pa-3 t-a-c ">
          {progress === 100 ? (
            <>
              <div className="">
                <img src={check} className="b-c-t  w-5" />
              </div>
              <div className="t-a-c f-s-1_5 m-b-2 f-w-600">Success</div>
            </>
          ) : (
            <>
              {" "}
              <div className="m-b-2">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
              <div className="m-b-2">
                {progress === 0
                  ? "Receiving asset"
                  : progress === 50
                  ? "Sending XAUS"
                  : ""}
              </div>
            </>
          )}

          <ProgressBar now={progress} className="m-b-2" />
          {progress === 100 ? (
            <>
              <div className="m-b-2 t-a-c">
                <span className="f-s-1_25">{amount / receiveXaus} XAUS</span>{" "}
                &nbsp; Transferred successfully.
              </div>
              <div
                className="or_clr b-r-10 d-i-b p-x-2 p-y-0_5 c-b f-w-600 c-p"
                onClick={() => setOpen(false)}
              >
                Close
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </>
  );
}

export default Home;
